<template>
  <v-container>
    <page-title icon="mdi-account-school" title="Peindre son casque" />

    <v-card class="my-5 pa-5 grey lighten-2">
      <div class="d-flex flex-column flex-md-row justify-space-around">
        <v-img
          class="rounded-xl mb-5"
          max-width="500"
          :src="require('@/assets/img/tuto/01_helmet/finished.jpg')"
        >
        </v-img>
        <div>
          <p>Difficulté: <b>Facile</b></p>
          <p>Durée: <b>2 à 3 heures</b></p>
          <p>
            Matériel:
            <b>
              <ul>
                <li>Casque de HEMA</li>
                <li>Imprimante</li>
                <li>Scotch double face</li>
                <li>Cutter ou ciseaux</li>
                <li>Scotch de carrossier</li>
                <li>Journeaux</li>
                <li>Peinture acrylique en spray</li>
              </ul>
            </b>
          </p>
        </div>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        1. Préparer le dessin
      </v-card-title>
      <div class="d-flex justify-center grey darken-4">
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/01.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          Après avoir choisi votre dessin, mesurez sur votre casque la surface
          que vous voulez couvrir.
        </p>
        <p>Imprimez votre dessin en noir et blanc à la bonne taille.</p>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        2. Appliquer le scotch double-face
      </v-card-title>
      <div
        class="d-flex flex-column flex-xl-row align-center justify-space-around grey darken-4"
      >
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/02.1.jpg')"
        >
        </v-img>
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/02.2.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          Appliquez du scotch double face au dos de la feuille, de manière à
          couvrir complètement le dessin.
        </p>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        3. Découper le dessin
      </v-card-title>
      <div
        class="d-flex flex-column flex-xl-row align-center justify-space-around grey darken-4"
      >
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/03.1.jpg')"
        >
        </v-img>
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/03.2.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          À l'aide d'un cutter ou de ciseaux, découpez le dessin.
        </p>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        4. Protéger le casque
      </v-card-title>
      <div
        class="d-flex flex-column flex-xl-row align-center justify-space-around grey darken-4"
      >
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/04.1.jpg')"
        >
        </v-img>
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/04.2.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          Protégez l'intérieur de votre casque ainsi que les parties extérieures
          que vous ne souhaitez pas peindre en utilisant du scotch de carrossier
          et du papier journal.
        </p>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        5. Coller le chablon
      </v-card-title>
      <div class="d-flex justify-center grey darken-4">
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/05.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          Collez le chablon sur le casque.
        </p>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        6. Appliquer la peinture
      </v-card-title>
      <div class="d-flex justify-center grey darken-4">
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/06.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          Utilisez de la peinture acrylique en spray.
        </p>
        <p>
          Faites plusieurs couches fines afin de ne pas boucher les trous de la
          grille. 3 à 5 couches fines suffises.
        </p>
        <p>
          Laisser sécher 5 minutes entre les couches et environ 30 minutes après
          avoir appliqué la dernière couche.
        </p>
      </div>
    </v-card>

    <v-card class="mb-5">
      <v-card-title class="grey darken-4 white--text">
        7. Retirer le chablon
      </v-card-title>
      <div class="d-flex justify-center grey darken-4">
        <v-img
          contain
          class="rounded-xl mb-5"
          :max-width="imageWidth"
          :src="require('@/assets/img/tuto/01_helmet/finished.jpg')"
        >
        </v-img>
      </div>
      <div class="mt-3 pa-5">
        <p>
          Retirer le chablon.
        </p>
        <p>
          Enfilez votre casque et retournez vous entraîner avec style !
        </p>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  computed: {
    imageWidth() {
      if (this.$vuetify.breakpoint.name == 'xs') {
        return 300
      } else if (this.$vuetify.breakpoint.name == 'sm') {
        return 500
      }
      return 800
    }
  }
}
</script>
